import format from 'date-fns/format';
import { de, es, fr, ja, ko, pt } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';
import { format as formatTZ, toDate } from 'date-fns-tz';

const LOCALE_DATA = { de, es, fr, ja, ko, pt };

export const formatSchedule = ({ date, time }) => {
  const localTime = time || new Date();
  const zonedTime = formatToISODate(
    localTime instanceof Date ? localTime : new Date(localTime),
  );
  const formatDate = format(date ? new Date(date) : new Date(), 'yyyy-MM-dd');
  return `${formatDate}T${zonedTime.split('T')[1]}`;
};

export const seconds = (ms) => ms / 1000;
export const hours = (seconds) => Math.round(parseInt(seconds / 3600, 10));
export const minutes = (seconds) =>
  Math.round(parseInt((seconds % 3600) / 60, 10));
export const readableSeconds = (seconds) =>
  Math.round(parseInt((seconds % 3600) % 60, 10));

export const formatRelease = (date) => format(parseISO(date), 'MMM do, yyyy');

export const TIME_ZONE = 'America/New_York';
export const SHORT_DATE_FORMAT = 'dd-MMM-yyyy';
export const LONG_DATE_FORMAT = 'MMM d, yyyy';
export const LONG_DATE_TIME_FORMAT = 'MMM d, yyyy HH:mm:ss';
export const DATE_FORMAT_WITH_DAY = 'eeee, MMM do, yyyy';
export const DATE_PLACEHOLDER = 'MM/DD/YYYY';
export const TIME_FORMAT = 'hh:mm a';
// Regex to validate user entered time. Allowed time is `HH:MM aaa` format.
export const TIME_FORMAT_REGEX =
  /^((1[0-2]|0?[1-9]):([0-5][0-9]) (am|AM|pm|PM))$/;
export const ISO_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXXXX";
export const MONTH_FORMAT = 'MMMM yyyy';

const UTC_TIME_ZONE = 'UTC';
const HOUR = 'hour';
const MONTH = 'month';

export const formatDate = (date) => {
  return format(new Date(date), SHORT_DATE_FORMAT);
};

export const formatUTCDateInFormat = (value, format) => {
  const dateString = (
    value instanceof Date ? value.toISOString() : value
  ).replace(/(Z.*)/, '');

  const date = toDate(dateString, UTC_TIME_ZONE);
  return formatTZ(date, format, { timeZone: UTC_TIME_ZONE });
};

export const formatUTCMonth = (date) => {
  return formatUTCDateInFormat(date, MONTH_FORMAT);
};

export const formatUTCDate = (date) => {
  return formatUTCDateInFormat(date, LONG_DATE_FORMAT);
};

export const formatUTCDateTime = (date) => {
  return formatUTCDateInFormat(date, LONG_DATE_TIME_FORMAT);
};

export const formatDateBy = (date, by) => {
  switch (by) {
    case MONTH:
      return formatUTCMonth(date);
    case HOUR:
      return formatUTCDateTime(date);
    default:
      // DAY
      return formatUTCDate(date);
  }
};

export const timeCaption = (by) => {
  switch (by) {
    case MONTH:
      return 'Months';
    case HOUR:
      return 'Hours';
    default:
      // DAY
      return 'Days';
  }
};

export const formatDateLongFormat = (dateStr) => {
  const date = new Date(dateStr);
  return format(date, LONG_DATE_FORMAT);
};

export const formatToISODate = (date) => {
  return format(new Date(date), ISO_DATE_FORMAT);
};

export const formatDateWithWeekday = (date) => {
  return format(date, DATE_FORMAT_WITH_DAY);
};

export const formatDateAndTimeWithText = (date, connectingText) => {
  if (connectingText) {
    return format(new Date(date), `MM/dd/yy '${connectingText}' h:mm a`);
  }
  return format(new Date(date), `MM/dd/yy h:mm a`);
};

export const formatFullDateAndTimeWithText = (date, connectingText) => {
  if (connectingText) {
    return format(new Date(date), `MMM dd, yyyy '${connectingText}' h:mm a`);
  }
  return format(new Date(date), `MMM dd, yyyy h:mm a`);
};

export const formatScheduledFor = (date) => {
  return format(new Date(date), "'Scheduled for' MM/dd/yy 'at' h:mm a");
};

/**
 *
 * @param dateStr {string} date string in ISO 8601 format (or other format accepted by JavaScript Date constructor)
 * @param locale {string} two-character locale code
 * @param formatOptions {string} formatting options in date-fns style
 * @returns {string}
 */
export const formatLocalizedDate = (
  dateStr,
  locale,
  formatOptions = 'PPPP p',
) => {
  if (dateStr) {
    // default format: Monday, March 9th, 2020 9:00 AM
    return format(new Date(dateStr), formatOptions, {
      locale: LOCALE_DATA[locale],
    });
  } else {
    return '';
  }
};

export const formatLocalTime = (date) => {
  return format(new Date(date), TIME_FORMAT);
};

export const displayTimeZone = (dateStr) => {
  const dateString = dateStr || new Date().toString();
  const tzAbbr =
    // Works for the majority of modern browsers
    dateString.match(/\(([^)]+)\)$/) ||
    // IE outputs date strings in a different format:
    dateString.match(/([A-Z]+) [\d]{4}$/);
  if (tzAbbr) {
    return tzAbbr[1].match(/[A-Z]/g)?.join('');
  }
  return '';
};

/**
 * @param date {string} a date in the form '2020-07-17'
 * @return {Date}
 */
export const dateWithLocalOffset = (date) => {
  const localDate = new Date(date);
  localDate.setTime(
    localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000,
  );
  return localDate;
};

export const formatSecondsToHoursDisplay = (
  valueInSeconds,
  nonZeroHours = false,
) => {
  const seconds = (valueInSeconds % 60).toString().padStart(2, '0');
  const minutes = (parseInt(valueInSeconds / 60) % 60)
    .toString()
    .padStart(2, '0');
  const hours = parseInt(valueInSeconds / 60 / 60)
    .toString()
    .padStart(2, '0');

  return nonZeroHours && Number(hours) === 0
    ? `${minutes}:${seconds}`
    : `${hours}:${minutes}:${seconds}`;
};

export const formatSecondsToDaysDisplay = (valueInSeconds) => {
  const seconds = (valueInSeconds % 60).toString().padStart(2, '0');
  const minutes = (parseInt(valueInSeconds / 60) % 60)
    .toString()
    .padStart(2, '0');
  const hours = parseInt(valueInSeconds / 60 / 60)
    .toString()
    .padStart(2, '0');

  if (Number(hours) === 0) {
    return `${minutes}m ${seconds}s`;
  } else if (Number(hours) < 96) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${parseInt(hours / 24)}d ${parseInt(hours % 24)
      .toString()
      .padStart(2, 0)}h`;
  }
};
